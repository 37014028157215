// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDv7yszfjVzvQHZZfjo9EJh2qN9NMV5kDU',
    authDomain: 'housinghelpdesk-cb0af.firebaseapp.com',
    databaseURL: 'https://housinghelpdesk-cb0af.firebaseio.com',
    projectId: 'housinghelpdesk-cb0af',
    storageBucket: 'housinghelpdesk-cb0af.appspot.com',
    messagingSenderId: '579396330222',
    appId: '1:579396330222:web:3bd4af9c43cb02e67109ff',
    measurementId: 'G-X2SKK1EZD2'
  },
  contentful: {
    spaceId: 'dokoiam6bpf2',
    token: '0UP3iesUl5pkt8sSSVtdwzItDHAXZatGG0U4gVb60VY'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
