import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentfulService } from './contentful.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
    private translate: TranslateService,
    private contentfulService: ContentfulService) {

    this.translate.addLangs(['en', 'nl']);

    const storedLanguage = this.getStoredLanguage();
    if (this.translate.getLangs().includes(storedLanguage)) {
      this.setLanguage(storedLanguage);
      this.contentfulService.changeLocale('en');
    } else {
      this.setLanguage('en');
    }
  }

  private storeLanguage(language: string) {
    localStorage.setItem('lang', language);
  }

  langButClicked(language: string) {
    if (this.translate.currentLang === language) { return; }
    this.storeLanguage(language);
    window.location.reload();
    window.scrollTo(0, 0);
  }

  setLanguage(language: string) {
    if (this.translate.getLangs().includes(language)) {
      this.translate.setDefaultLang(language);
      this.translate.use(language);
      this.storeLanguage(language);
    }
  }

  getStoredLanguage(): string {
    return localStorage.getItem('lang');
  }

}
