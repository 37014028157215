import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin-pages/admin-pages.module').then(
        (m) => m.AdminPagesModule
      ),
  },
  {
    path: 'planner',
    loadChildren: () =>
      import('./planner/planner.module').then((m) => m.PlannerModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'downloads',
    loadChildren: () =>
      import('./downloads/downloads.module').then((m) => m.DownloadsModule),
  },
  {
    path: 'legislation',
    loadChildren: () =>
      import('./columnContent/columnContent.module').then(
        (m) => m.ColumnContentModule
      ),
    data: { contentId: '2oiSn5WoUWlhTROPNJmH8W' },
  },
  {
    path: 'legislation/:subject',
    loadChildren: () =>
      import('./columnContent/columnContent.module').then(
        (m) => m.ColumnContentModule
      ),
    data: { contentId: '2oiSn5WoUWlhTROPNJmH8W' },
  },
  {
    path: 'what-we-do',
    loadChildren: () =>
      import('./columnContent/columnContent.module').then(
        (m) => m.ColumnContentModule
      ),
    data: { contentId: '5AomihHpsW5ugW9u18cKyH' },
  },
  {
    path: 'what-we-do/:subject',
    loadChildren: () =>
      import('./columnContent/columnContent.module').then(
        (m) => m.ColumnContentModule
      ),
    data: { contentId: '5AomihHpsW5ugW9u18cKyH' },
  },
  {
    path: 'cancel',
    loadChildren: () =>
      import('./cancel/cancel.module').then((m) => m.CancelModule),
  },
  {
    path: 'page',
    loadChildren: () =>
      import('./content/content.module').then((m) => m.ContentModule),
  },
  { path: 'links', loadChildren: () => import('./links/links.module').then(m => m.LinksModule) },
  {
    path: '**',
    loadChildren: () =>
      import('./not-found/not-found.module').then((m) => m.NotFoundModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
