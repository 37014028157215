<!-- Corona modal -->
<div class="modal" [ngClass]="{'is-active': showPopup}" *ngIf="popupContent as content">
  <div class="modal-background"></div>
  <div class="modal-content">
    <article class="message is-dark">
      <div class="message-header">
        <p>{{content.title}}</p>
      </div>
      <div class="message-body">
        <span class="flag-icon flag-icon-nl"></span>
        <p [innerHTML]="content.contentNl | mdToHtml">
        </p>
        <hr>
        <span class="flag-icon flag-icon-gb"></span>
        <p [innerHTML]="content.contentEng | mdToHtml">
        </p>

        <button class="button-orange" (click)="showPopup = false;">{{content.buttonText}}</button>
      </div>
    </article>
  </div>
</div>

<app-navbar-top></app-navbar-top>
<div class="router-container">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
