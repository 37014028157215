import { Injectable } from '@angular/core';
import * as contentful from 'contentful';
import { environment } from 'src/environments/environment';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  public locale;
  private gotLocale = false;

  private options: any = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: { fields },
        },
      }) =>
        `<img src="${fields.file.url}" height="${fields.file.details.image.height}" width="${fields.file.details.image.width}" />`,
      [BLOCKS.UL_LIST]: ({ content }) => {
        let list = '<ul>';
        content.forEach((element) => {
          const li = element.content[0].content[0].value;
          list += `<li>${li}</li>`;
        });
        list += '</ul>';
        return list;
      }
    },
  };

  private client = contentful.createClient({
    space: environment.contentful.spaceId,
    accessToken: environment.contentful.token,
  });

  constructor() {}

  private getLocale() {
    const storedLang = localStorage.getItem('lang');
    if (storedLang === 'nl' || storedLang === 'en') {
      this.changeLocale(storedLang);
    } else {
      this.changeLocale('en');
    }
  }

  getContent(contentId: string): Observable<any> {
    if (!this.gotLocale) {
      this.getLocale();
      this.gotLocale = true;
    }

    const promise = this.client.getEntry(contentId, { locale: this.locale });
    return from(promise).pipe(map((entry: any) => entry.fields));
  }

  toHtmlString(field: any) {
    return documentToHtmlString(field, this.options);
  }

  changeLocale(locale: string) {
    if (locale === 'nl') {
      this.locale = 'nl-NL';
    } else if (locale === 'en') {
      this.locale = 'en-US';
    }
  }
}
