import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AngularFireModule } from "@angular/fire";
import { environment } from "src/environments/environment";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ComponentsModule } from "./components/components.module";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { AngularFireAuthModule } from "@angular/fire/auth";
import {
  AngularFireAnalyticsModule,
  CONFIG,
  ScreenTrackingService,
} from "@angular/fire/analytics";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MdToHtmlPipeModule } from "./core/pipes/md-to-html-pipe.module";
import { MatNativeDateModule } from "@angular/material/core";
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from "ngx-cookieconsent";

const cookieConfig: NgcCookieConsentConfig = {
  "cookie": {
    "domain": "huurteam-zuidlimburg.nl"
  },
  "position": "bottom-right",
  "theme": "block",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#e84e10",
      "text": "#ffffff",
      "border": "transparent"
    }
  },
  "type": "opt-in",
  "content": {
    "message": "This website uses cookies for analytics and to ensure you get the best experience on our website.",
    "dismiss": "x",
    "deny": "Only essential cookies",
    "link": "Learn more",
    "href": "/page/privacy",
    "policy": "Cookie Policy"
  }
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: "nl",
    }),
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    BrowserAnimationsModule,
    ComponentsModule,
    MdToHtmlPipeModule,
    MatNativeDateModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
    ScreenTrackingService,
    {
      provide: CONFIG,
      useValue: {
        allow_ad_personalization_signals: false,
        anonymize_ip: true,
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
