<nav id="navTop" [ngClass]="{'atTop animated' : !atTop}" class="navbar is-fixed-top" role="navigation"
  aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" [routerLink]="['/']">
      <img src="/assets/img/Huurteam-zuidlimburg-LOGO-01.png" alt="Huurteam-Zuidlimbirg logo">
    </a>
    <a (click)="toggleNavbar()" #navBurger role="button" class="navbar-burger burger" aria-label="menu"
      aria-expanded="false" data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div class="navbar-menu" #navMenu>
    <div class="navbar-start">
      <ng-container *ngIf="isMobile">
        <!-- Facebook -->
        <div class="navbar-item icons-right-item">
          <a href="https://www.facebook.com/huurteamzuidlimburg/" target="_blank" rel="noopener">
            <img class="flag" src="/assets/img/facebook.svg" alt="Facebook">
          </a>
        </div>
        <!-- LinkedIn -->
        <div class="navbar-item icons-right-item">
          <a href="https://www.linkedin.com/company/huurteam-zuid-limburg" target="_blank" rel="noopener">
            <img class="flag" src="/assets/img/linkedin.svg" alt="Facebook">
          </a>
        </div>
        <!-- Languages  -->
        <div class="navbar-item icons-right-item">
          <a (click)="languageService.langButClicked('nl')">
            <img class="flag" src="/assets/img/flags/nl.svg" alt="Dutch">
          </a>
        </div>
        <div class="navbar-item icons-right-item">
          <a (click)="languageService.langButClicked('gb')">
            <img class="flag" src="/assets/img/flags/gb.svg" alt="English">
          </a>
        </div>
      </ng-container>

      <!-- About us -->
      <a class="navbar-item" [routerLink]="['/page/about-us']">
        {{ 'NAVTOP.ABOUT' | translate }}
      </a>

      <!-- What we do -->
      <a class="navbar-item" [routerLink]="['/what-we-do']">
        {{ 'NAVTOP.WHATWEDO' | translate }}
      </a>

      <!-- Legislation -->
      <a class="navbar-item" [routerLink]="['/legislation']">
        {{ 'NAVTOP.LEGISLATION' | translate }}
      </a>

      <!-- Downloads -->
      <a class="navbar-item" [routerLink]="['/downloads']">
        Downloads
      </a>

      <!-- Downloads -->
      <a class="navbar-item" [routerLink]="['/faq']">
        FAQ
      </a>

      <!-- Appointment -->
      <a class="navbar-item" [routerLink]="['/planner']">
        <button mat-raised-button color="primary">{{ 'NAVTOP.APPOINTMENT' | translate }}</button>
      </a>
    </div>

    <div class="navbar-end" *ngIf="!isMobile">
      <!-- Facebook -->
      <div class="navbar-item icons-right-item">
        <a href="https://www.facebook.com/huurteamzuidlimburg" target="_blank" rel="noopener">
          <img class="flag" src="/assets/img/facebook.svg" alt="Facebook">
        </a>
      </div>
      <!-- LinkedIn -->
      <div class="navbar-item icons-right-item">
        <a href="https://www.linkedin.com/company/huurteam-zuid-limburg" target="_blank" rel="noopener">
          <img class="flag" src="/assets/img/linkedin.svg" alt="Linkedin">
        </a>
      </div>
      <!-- Languages  -->
      <div class="navbar-item icons-right-item">
        <a (click)="languageService.langButClicked('nl')">
          <img class="flag" src="/assets/img/flags/nl.svg" alt="Dutch">
        </a>
      </div>
      <div class="navbar-item icons-right-item">
        <a (click)="languageService.langButClicked('gb')">
          <img class="flag" src="/assets/img/flags/gb.svg" alt="English">
        </a>
      </div>
    </div>
  </div>
</nav>
