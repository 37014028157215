<footer class="footer">
  <div class="columns is-centered">
    <div class="column is-6">
      <ul class="has-text-right">
        <li>Huurteam Zuid-Limburg</li>
        <li>{{ "FOOTER.UNI" | translate}}</li>
        <li>Bonnefantenstraat 2</li>
        <li>6211 KL Maastricht, {{ "FOOTER.NL" | translate}}</li>
        <li>KvK: 77264525</li>        
      </ul>
    </div>
    <div class="column is-6">
      <ul class="has-text-right">
        <li class="clickable" [routerLink]="['/faq']">FAQ</li>
        <li><a href="mailto:info@huurteam-zuidlimburg.nl">info@huurteam-zuidlimburg.nl</a></li>
        <li>043-388 36 71</li>
        <li class="clickable" [routerLink]="['/page/privacy']">Privacy statement</li>
        <li class="clickable" [routerLink]="['/links']">Links</li>
      </ul>
    </div>
  </div>

  <div class="columns is-centered" style="background-color: white;">
    <div class="column has-text-centered is-vertical-center">
      <a href="https://www.gemeentemaastricht.nl/" target="_blank" rel="noopener">
        <img style="margin-left: 70px;" src="./assets/img/partners/logo-gemeente-maastricht.png" alt="">
      </a>
    </div>
    <div class="column has-text-centered is-vertical-center">
      <a href="https://www.maastrichtuniversity.nl/" target="_blank" rel="noopener">
        <img src="./assets/img/partners/uni-maastricht-logo.png" alt="">
      </a>
    </div>
    <div class="column has-text-centered is-vertical-center">
      <a href="https://mymaastricht.nl/" target="_blank" rel="noopener">
        <img src="./assets/img/partners/logo-mymaastricht-600.png" alt="">
      </a>
    </div>
    <div class="column has-text-centered">
      <a href="https://www.eszl.nl/" target="_blank" rel="noopener">
        <img src="./assets/img/partners/Logo-ESZL-blauw.png" alt="">
      </a>
    </div>
  </div>
</footer>
