import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { LanguageService } from 'src/app/core/services/language.service';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-navbar-top',
  templateUrl: './navbar-top.component.html',
  styleUrls: ['./navbar-top.component.scss']
})
export class NavbarTopComponent implements OnInit {

  @ViewChild('navBurger') navBurger: ElementRef;
  @ViewChild('navMenu') navMenu: ElementRef;

  atTop = true;
  isMobile = false;

  constructor(
    private router: Router,
    public languageService: LanguageService,
    private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe('(min-width: 1024px)').subscribe(result =>
      this.isMobile = !result.matches
    );
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.navBurger.nativeElement.classList.remove('is-active');
        this.navMenu.nativeElement.classList.remove('is-active');
      }
    });
  }

  toggleNavbar() {
    this.navBurger.nativeElement.classList.toggle('is-active');
    this.navMenu.nativeElement.classList.toggle('is-active');
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (window.scrollY === 0) {
      this.atTop = true;
    } else {
      this.atTop = false;
    }
  }
}
