import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import {
  NgcCookieConsentService,
  NgcStatusChangeEvent,
} from "ngx-cookieconsent";
import { Subscription } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  popupContent: any;
  showPopup = false;

  constructor(
    private router: Router,
    private ccService: NgcCookieConsentService
  ) {}

  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;

  private loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement("script");
    script.innerHTML = "";
    script.src = "https://www.googletagmanager.com/gtag/js?id=UA-175583576-1";
    script.async = true;
    script.defer = true;
    body.appendChild(script);
    let script2 = document.createElement("script");
    script2.innerHTML =
      "window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-175583576-1', { 'anonymize_ip': true });";
    body.appendChild(script2);
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    function getCookie(cookieName) {
      let cookie = {};
      document.cookie.split(";").forEach(function (el) {
        let [key, value] = el.split("=");
        cookie[key.trim()] = value;
      });
      return cookie[cookieName];
    }

    const consentStatus = getCookie("cookieconsent_status");

    if (consentStatus === "allow") {
      this.loadScript();
    }

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        if (event.status === "deny") {
          return;
        }

        if (event.status === "allow") {
          this.loadScript();
        }
      }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );
  }

  ngOnDestroy() {
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
  }
}
